.more-link {
    $content-margin: responsive-property(xsmall, content-margin);
    padding: 0 $content-margin;

    a {
        font-family: "Sharelife Semi Bold";
    }

    @include media-width(min-width, small) {
        padding: 0;
    }

    @include media-width(min-width, mega-large) {
        flex: auto;
        text-align: right;
    }
}