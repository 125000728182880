.component.component--soundcloud {
    $content-width: responsive-property(xsmall, content-width);
    $offset-v: calc($content-width * 0.65);
    $color-sc-gray: #666;
    $color-sc-gray-mid: #999;
    $color-sc-gray-light: #aaa;

    display: flex;
    flex-wrap: wrap;
    margin: $leading-half 0;
    padding-bottom: 0;
    padding-top: 0;
    position: relative;

    .project__intro+& {
        margin-top: $leading;
    }

    .soundcloud-wrapper {
        width: 100%;

        &--compact {
            margin-top: 3rem;

            .media-submitted & {
                margin-top: 4.75rem;
            }
        }
    }

    h4 {
        margin-top: $leading-half;
    }

    h4 + p {
        margin-top: 0;
    }

    .soundcloud-placeholder {
        background: $color-white;
        border-radius: 3px;
        box-sizing: border-box;
        color: $color-sc-gray;
        cursor: pointer;
        display: block;
        height: 5rem;
        padding: 0.5rem;
        opacity: 1;
        position: relative;
        position: relative;
        width: 100%;
        transition: opacity 0.3s;

        .safari & {
            break-inside: avoid;
        }

        .soundcloud--on & {
            opacity: 0;
        }

        &:before {
            background-image: url("../img/sc_soundwave.png");
            background-repeat: repeat-x;
            background-size: 6.1875rem 2.25rem;
            content: "";
            display: block;
            height: 2rem;
            position: absolute;
            top: 4.3125rem;
            width: calc(100% - 1rem);
        }

        &:after {
            color: #aaa;
            content: "\e903";
            display: block;
            font-family: "icomoon";
            font-size: 1.18rem;
            position: absolute;
            right: 0.3rem;
            top: 0.5rem;
            z-index: 100;
        }

        h4 {
            font-family: "Sharelife Light";
            font-size: 1rem;
            margin-left: 3.2rem;
            margin-top: 1.5rem;

            &:before {
                background-color: #ff3e00;
                border-radius: 50%;
                border: 0.03125rem solid $color-sc-gray;
                box-sizing: border-box;
                color: #fff;
                content: "\e901";
                display: block;
                font-family: "icomoon";
                font-size: 0.862rem;
                height: 2.7rem;
                left: 0.5rem;
                padding-top: 0.6rem;
                position: absolute;
                text-align: center;
                top: 0.6rem;
                transform: rotate(90deg);
                width: 2.7rem;
            }

            &:after {
                content: "Together Productions";
                display: block;
                font-family: "Sharelife Light";
                font-size: 0.8rem;
                left: 3.65rem;
                position: absolute;
                top: 0.9rem;
            }
        }

        a {
            bottom: -0.2rem;
            color: $color-sc-gray-mid;
            font-family: "Sharelife Semi Bold";
            font-size: 0.67rem;
            position: absolute;
            text-decoration: none;
        }
    }

    iframe,
    .soundcloud-placeholder {
        height: 120px;
        margin-top: -3rem;
        width: 100%;
        z-index: 3;
    }

    iframe {
        margin-top: -7.5rem;
        pointer-events: none;
        position: absolute;

        width: calc(100% - 2.5rem);
        right: 1.2rem;

        .soundcloud--on & {
            pointer-events: all;
        }
    }

    // Custom breakpoint
    @media(min-width: 375px) {
        .soundcloud-placeholder:after {
            color: $color-sc-gray-light;
            content: "\e902";
        }
    }

    // Custom breakpoint
    @media(min-width: 400px) {

        margin: $leading 0 0;

        .soundcloud-text {
            margin: $leading 3rem 0 3rem;

            h4 {
                margin-top: 0;
            }

            p:last-child {
                margin-bottom: 0;
            }

            :last-child & {
                margin-bottom: $leading;
            }
        }
    }

    @include media-width(min-width, small) {
        iframe {
            right: auto;
            width: 100%;
        }
    }

    // Custom breakpoint
    @media (min-width: 700px)  and (max-width: 1299px) {
        .project:not(.component--project--song) &:first-child iframe {
            width: calc(50% - 2rem);
        }

        .soundcloud-placeholder:after {
            content: "\e903";
        }
    }

    @include media-width(min-width, mega-large) {
        .soundcloud-placeholder {
            &:after {
                content: "\e903";
            }
        }
        iframe {
            .safari & {
                width: calc(100% - 2rem);
            }
        }

        .soundcloud-text {
            margin-bottom: $leading;
        }
    }
}