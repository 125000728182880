.modal-wrapper--page {
    
    .modal--page--active & {
        opacity: 1;
        pointer-events: all;
    }

    /* Separate style for visibility so can be removed once fade completes */
    .modal--page--vis & {
        visibility: visible;
    }
    
    .details {
        font-family: "Sharelife Semi Bold";

        .details-note {
            display: block;
            font-family: "Sharelife Light";
            font-style: 0.7rem;
            margin-top: 0.5rem;
        }

        h4 {
            flex: 100%;
        }
        
        .switch {
            flex: auto 0 0;
            transform: scale(0.75);
            margin: 0 0 0.5rem -0.7rem;
        }

        .switch-purpose {
            flex: 10%;
        }
    }

    .form__input--web {
        display: none;
    }
}