.modal-wrapper--service {
    
    .modal--service--active & {
        opacity: 1;
        pointer-events: all;
    }

    /* Separate style for visibility so can be removed once fade completes */
    .modal--service--vis & {
        visibility: visible;
    }

}

.modal--service {

    .consent-form {
        display: none;
    
        .form__input--web {
            display: none;
        }
    }
    
    .modal--service--active--youtube & .consent-form--youtube,
    .modal--service--active--soundcloud & .consent-form--soundcloud {
        display: block;
    }
}