.close_bttn {
    $bar-height: 3px;
    $bar-width: 26px;
    $toggle-size: 17px;
    
    cursor: pointer;
    height: $toggle-size;
    padding: 30px 10px 14px 0;
    position: relative;
    top: 0;
    width: $toggle-size;
    z-index: 1;

    &-bar {
        position: absolute;
        width: $bar-width;

        &:before {
            top: -8px;
            transform: rotate(45deg);
        }

        &:after {
            top: 8px;
            transform: rotate(135deg);
        }
        &:before,
        &:after {
            content: "";
            background-color: $color-white;
            height: $bar-height;
            left: 0;
            position: absolute;
            width: $bar-width;
            top: 0;
        }

        .close_bttn--dark & {
            background-color: rgba(21, 28, 36, 0);
    
            &:before,
            &:after {
                content: "";
                background-color: $color-midnight;
            }

            .toggle-active & {
                background-color: rgba(255, 255, 255, 0);
            }
        }
    }
}