.component.component--intro {
    .trigger-popup {
        cursor: pointer;
    }

    .hero-image + p {
        display: none;
    }

    &.component--intro--overview {
        padding-top: 0;
    }

    .overview {
        margin-top: 0;

        .primary-title-block {
            margin-top: -0.4rem;
        }

        p:nth-child(3) {
            margin-top: calc($leading * 2.5);
        }
    }

    figure:after {
        pointer-events: none;
    }

    figcaption {
        font-family: "Sharelife Light";

        h3,
        h3 + p {
            margin: 0;
        }
    }

    .more-link {
        @extend %text-red-reversed;
        padding-top: $leading;
        padding-bottom: $leading;

        a {
            text-align: center;
            display: inline-block;
            width: 100%;
        }
    }

    main & .pdf .download-link .download-details {
        color: $color-buff;
    }

    .pdf {
        margin-bottom: $component-gap;
        position: relative;

        h3 {
            color: $color-white;
            position: absolute;
            left: 4.2rem;
            top: 0.1rem;
        }
    }

    @media (min-width: 700px) {
        padding-bottom: 4rem;

        .overview-feature {
            display: grid;
            column-gap: 1rem;
            grid-template-columns: 1px 1fr 1fr 1px;
            grid-template-areas:
                "text1 text1 text1 text1"
                "text2 text2 text2 text2"
                "link link link link"
                "image1 image1 image2 image2"
                "pdf pdf pdf pdf";
        }

        .pdfs {
            grid-area: pdf;

            .pdf {
                margin: $component-gap 0 0;
            }
        }

        figcaption {
            margin: 0;
            padding: 1rem 0 0 2.5rem;
            text-align: left;
        }

        figure {
            align-self: end;
            margin: $leading 0 0;
        }

        p:nth-child(1) {
            grid-area: text1;
        }

        figure:nth-child(2) {
            grid-area: image1;
        }

        p:nth-child(3) {
            grid-area: text2;
            text-indent: 1em;
        }

        figure:nth-child(4) {
            grid-area: image2;
            align-self: start;
        }

        .more-link {
            grid-area: link;
            margin-top: $leading-double;
            padding: 0 0 0 2.5rem;

            a {
                text-align: left;
            }
        }

    }

    @include media-width(min-width, mega-large) {
        margin-top: 2rem;

        .overview-feature {
            column-gap: 2rem;
            grid-template-rows: 14.25rem max-content max-content;
            grid-template-columns: 1fr 750px;
            grid-template-areas:
                ". image"
                "text1 image"
                "text2 image"
                "text2 pdf";

            margin-top: -15.5rem;
        }

        figure {
            display: none;
        }

        p:nth-child(1) {
            grid-area: text1;
            margin-bottom: -1rem;
        }

        p:nth-child(3) {
            grid-area: text2;
            margin-top: -0.9rem;
        }

        .hero-image,
        .media-wrapper--youtube {
            grid-area: image;

            img {
                width: 100%;
            }

            & + p {
                display: block;
                grid-area: caption;
                font-family: "Sharelife Light";
                margin-top: $leading-half;
                padding-right: 15rem;
            }
        }

        .pdfs {
            grid-area: pdf;

            .pdf {
                margin-top: 0;
            }

            .download-details {
                padding-left: 4.4rem;
            }

        }

        .more-link {
            grid-area: link;
            margin-top: -1.3rem;
            padding-left: 0;
            position: relative;
            z-index: 1;

            a {
                text-align: right;
            }
        }
    }
}