.component.component--youtube {
    $content-width: responsive-property(xsmall, content-width);
    $video-height: calc($content-width * 0.5625);
    padding: 0;
    width: 100%;


    .youtube-wrapper {
        background-color: $color-midnight;
        height: $video-height;
        position: relative;
    }

    .intro & .youtube-wrapper {
        margin-bottom: $component-gap;
    }

    .youtube-placeholder {
        position: absolute;
    }

    .event-overlay {
        background-image: url("../img/youtube_bttn_overlay.svg");
        background-repeat: no-repeat;
        cursor: pointer;
        display: block;
        height: calc($content-width * 0.5625);
        position: absolute;
        width: $content-width;
        z-index: 1;
    }

    .youtube--loaded &.active .event-overlay {
        opacity: 0;
    }

    iframe,
    .youtube-placeholder {
        height: $video-height;
        width: $content-width;
        opacity: 1;
        pointer-events: none;
        transition: opacity 0.3s;
    }

    &.active {
        .youtube-wrapper .event-overlay {
            /* Initially pointer events are required so the event-overlay can be clicked to load the video */
            pointer-events: all;

            .youtube--loaded & {
                /* Once a video has been loaded, control passes to the iframe */
                pointer-events: none;
            }
        }

        iframe {
            /* Activate iframe clicks */
            cursor: pointer;
            pointer-events: all;
        }
    }

    .youtube--loaded &.active .youtube-placeholder {
        opacity: 0;
    }

    @include media-width(min-width, small) {
        $text-width: responsive-property(small, text-width);
        $video-height: calc($text-width * 0.5625);

        .youtube-wrapper {
            height: $video-height;
        }

        .event-overlay {
            height: calc($text-width * 0.5625);
            width: $text-width;
        }

        .intro & .event-overlay {
            width: 100%;
            height: 422px;
        }

        iframe,
        .youtube-placeholder {
            height: $video-height;
            width: $text-width;
        }
    }

    // Custom breakpoint
    @media (min-width: 700px) {
        $text-width: 490px;
        $video-height: calc($text-width * 0.5625);

        .youtube-wrapper {
            height: $video-height;
        }

        iframe,
        .youtube-placeholder {
            height: $video-height;
            width: $text-width;
        }

        .intro & {
            $video-height: 275.625px;
            $video-width: 490px;

            .youtube-wrapper {
                margin: 2rem 0;
                height: $video-height;
                width: $video-width;
            }

            iframe,
            .youtube-placeholder {
                height: 100%;
                width: 100%;
            }
        }
    }

    // Custom breakpoint
    @media (min-width: 700px) and (max-width: 1299px) {
        $text-width: 490px;

        .event-overlay {
            height: calc($text-width * 0.5625);
            width: $text-width;
        }
    }

    @include media-width(min-width, mega-large) {

        iframe,
        .youtube-placeholder {
            width: 100%;
            height: 100%;
        }

        .intro & {
            $video-height: 422px;
            $video-width: 750px;

            .youtube-wrapper {
                height: $video-height;
                margin: 0;
                width: $video-width;

                .event-overlay {
                    width: 100%;
                    height: 100%;
                }
            }

            iframe,
            .youtube-placeholder {
                height: $video-height;
                width: $video-width;
            }
        }
    }
}