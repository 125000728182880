.component.component--feature {
    padding-top: $leading-half;

    &.component--feature--scores {
        padding-top: 0;
    }

    .content-feature {
        @extend %text-buff;

        display: flex;
        flex-direction: column;

        h2 {
            padding: 1.5rem 0 0;
        }

        h2 + .feature__text-block {
            margin-top: $leading;
        }

        h3 {
            color: $color-midnight;
            font-family: "Prater Sans";
            font-size: 1.7rem;
            line-height: 1.8rem;
            text-transform: uppercase;
        }
        p,
        h4 {
            color: $color-buff;
        }

        p {
            font-family: "Sharelife Light";
        }

        figure figcaption {
            color: $color-buff;
            font-family: "Sharelife Light";
            width: 75%;
        }

        .feature__text-block h4 {
            margin-top: 0;
        }

        &.scores .feature__text-block {
            display: none;
        }
    }

    @media (min-width: 700px) {
        padding-bottom: $leading-double;

        &:last-child .content-feature:not(.history) {
            display: grid;
            column-gap: 1rem;
            grid-template-columns: 1px 1fr 1fr 1px;
            grid-template-areas:
                "title title title title"
                "text1 text1 text1 text1"
                "text2 text2 text2 text2"
                "text3 text3 text3 text3"
                "image1 image1 image2 image2 ";

            h2 {
                grid-area: title;
            }

            .feature__text-block:nth-child(2) {
                grid-area: text1;
            }

            .feature__text-block:nth-child(4) {
                grid-area: text2;
            }

            .feature__text-block:nth-child(6) {
                grid-area: text3;
            }

            figure:nth-child(3) {
                grid-area: image1;
            }

            figure:nth-child(5) {
                grid-area: image2;
                align-self: start;
            }
        }

        &:last-child .content-feature {
            flex-flow: row wrap;
            justify-content: space-between;

            figure:nth-child(5) {
                width: 18.5rem;

                &:after {
                    font-size: 20.5rem;
                    top: 8.5rem;
                }

                figcaption {
                    height: 5rem;
                    margin: $leading 0 0;
                    padding: 0;
                    text-align: left;
                }

                img {
                    height: 18.5rem;
                    width: 18.5rem;
                }
            }

            figure:nth-child(3) {
                align-content: end;
                align-self: flex-end;
                height: 25rem;
                width: 8rem;

                &:after {
                    font-size: 14rem;
                    bottom: 5.72rem;
                    top: auto;
                }

                figcaption {
                    bottom: 13rem;
                    left: auto;
                    margin: $leading-half 0;
                    padding: 0;
                    position: absolute;
                    right: 0;
                    text-align: left;
                    top: auto;
                    width: 9rem;
                }

                img {
                    height: 13rem;
                    order: 1;
                    width: 13rem;
                }
            }

            figure:nth-child(5) {
                order: 1;
            }

            figure:nth-child(3) {
                order: 2;
            }

            .more-link {
                order: 3;
            }

        }
    }

    @include media-width(min-width, mega-large) {

        &.component--feature--scores {
            padding-bottom: $leading-double;
            padding-top: $leading;

            .content-feature.scores {
                display: flex;

                h3 {
                    flex: auto;
                    width: 21.875rem;
                }

                .pdfs {
                    column-gap: 2rem;
                    display: flex;
                    flex: auto;
                    margin-top: -3rem;
                    width: 46.875rem;

                    .pdf {
                        flex: 1;

                        .download-link .download-details {
                            padding-right: 0rem;
                        }
                    }
                }
            }

        }

        &:last-child {
            padding-top: 3.5rem;

            .content-feature {
                display: grid;
                column-gap: 2rem;
                grid-template-columns: 26rem 2fr 1fr;
                grid-template-areas:
                    "title . image2"
                    "text1 image1 image2"
                    "text2 image1 image2"
                    "text3 image1 image3";
                align-items: start;
    
                h2 {
                    grid-area: title;
                    padding-top: 0;
                }
    
                h4 {
                    padding-top: 0;
                }
    
                .feature__text-block:nth-child(2) {
                    grid-area: text1;
                    margin-top: 0;
                }
    
                .feature__text-block:nth-child(4) {
                    grid-area: text2;
                }
    
                .feature__text-block:nth-child(6) {
                    grid-area: text3;
                }
                
                figure:nth-child(5) {
                    grid-area: image1;
                    justify-self: center;
                }
    
                figure:nth-child(3) {
                    grid-area: image2;
                    align-items: flex-start;
                    align-self: start;

                    &:after,
                    .safari &:after {
                        bottom: auto;
                        font-size: 16rem;
                        top: 13rem;
                    }

                    figcaption,
                    .safari & figcaption {
                        bottom: auto;
                    }

                    img {
                        position: absolute;
                        bottom: auto;
                        height: 15rem;
                        top: 6rem;
                        width: 15rem;
                    }
                }
                
    
                figure {
                    figcaption {
                        margin: $leading-half 0 0;
                        padding: 0.65625rem 0 0;
                        text-align: left;
                    }
    
                    &:nth-child(5) {
                        width: 23.75rem;
    
                        &:after {
                            font-size: 25.5rem;
                            top: 11.2rem;
                        }
    
                        img {
                            height: 23.75rem;
                            width: 23.75rem;
                        }
                    }
                    
                    &:nth-child(3) {
                        width: 14rem;
                    }
                }
            }
        }
        
        div:last-child {
            align-self: flex-end;
            grid-area: image3;
        }
    }
}

    
