/* Prater Sans */
@font-face {
    font-family: "Prater Sans";
    font-style: normal;
    src: url("../fonts/Prater-Sans-Regular.woff") format("woff");
}

/* Sharelife Light - woff version of catamaran */
@font-face {
    font-family: "Sharelife Light";
    font-style: normal;
    src: url("../fonts/Sharelife-Light.woff") format("woff");
}

/* Sharelife Semi Bold - woff version of catamaran */
@font-face {
    font-family: "Sharelife Semi Bold";
    font-style: normal;
    src: url("../fonts/Sharelife-SemiBold.woff") format("woff");
}

/* Image rings */
@font-face {
    font-family: "icomoon";
    src: url("../fonts/icomoon.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: "icomoon" !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}