@import "./variables",
"./helpers",
"./reset.scss",
"./fonts.scss",
"../components/intro/intro",
"../components/project/project",
"../components/feature/feature",
"../components/callout/callout",
"../components/youtube/youtube",
"../components/soundcloud/soundcloud",
"../components/stories/stories",
"../components/modals/modal",
"../components/modals/page_modal/page_modal",
"../components/modals/service_modal/service_modal",
"../components/ui/link_more/link_more";

// Overrides imported at end of file



$content-margin: responsive-property(xsmall, content-margin);
$content-width: responsive-property(xsmall, content-width);
$text-width: responsive-property(xsmall, text-width);

// Set outer quotes to single, nested to double
:lang(en) > * { quotes: "\2018" "\2019" "\201C" "\201D" }

body {
    font-family: "Sharelife Light";
    background: $color-midnight;
    color: $color-buff;

    &.nav-active,
    &.modal--page--active,
    &.modal--service--active,
    &.show-hero-popup {
        overflow: hidden;
    }
}

h1,
h2,
h3,
h4,
h5 {
    text-wrap: balance;
}

h1,
h2 {
    font-family: "Prater Sans";
    text-transform: uppercase;
}

h1 {
    color: $color-primary;
    font-size: 3.4125rem;
    line-height: 3.515rem;
    padding-top: 1.2rem;

    .home & {
        @extend %screen-reader-text;
    }
}

h2 {
    color: $color-primary;
    font-size: 2.625rem;
    line-height: 2.704rem;
    padding: 3rem;
}

h3, h4 {
    font-family: "Sharelife Semi Bold";
    margin-top: $leading;
    text-transform: none;
}

h3 {
    font-size: 1.25rem;
    line-height: 1.45rem;
}

.story h4 {
    margin-top: $leading-half;
    text-wrap: balance;
}

p {
    font-family: "Sharelife Light";

    h3 + &,
    h4 + & {
        margin-top: 0;
        padding-top: 0;
    }

    p + & {
        text-indent: 1em;

        &.full-out {
            margin-top: $leading;
            text-indent: 0;
        }
    }

    .lead-in,
    strong {
        font-family: "Sharelife Semi Bold";
    }
}

blockquote,
blockquote p,
.period blockquote + p {
    color: $color-primary;
    font-family: "Prater Sans";
    text-transform: uppercase;
}


blockquote {
    font-size: 2rem;
    line-height: 2.3125rem;
    &:before {
        content: open-quote;
        display: inline;
    }
    &:after {
        content: close-quote;
        display: inline;
    }

    p {
        display: inline;
    }

    .component--period--medieval-thurrock & ~ p {
        text-indent: 0;
    }
}

.period blockquote + p {
    font-size: 1.09375rem;
    line-height: 1.3125rem;

    .name {
        color: $color-buff;
    }
}

.primary-palette,
.secondary-palette {
    background: $color-primary;
    color: $color-midnight;

    a {
        &:link {
            color: $color-midnight;
        }

        &:visited {
            color: $color-primary-dark;
        }

        &:hover {
            color: $color-red;
        }
    }

    h2 {
        color: $color-midnight;
    }
}

.secondary-palette {
  background: rgb(225, 196, 154);

  .scores .download-link .download-details {
    color: $color-midnight;
  }
}

.dark-palette {
    background: $color-midnight;
    color: $color-buff;

    a {
        &:link {
            color: $color-primary-light;
        }

        &:visited {
            color: $color-red-light;
        }

        &:hover {
            color: $color-red;
        }
    }

    h2 {
        color: $color-primary;
    }
}

small {
    font-size: 0.85rem;
}

cite {
    font-style: italic;
}

.overview,
.component,
.graphic_oral_histories h2,
.live_sketches h2 {
    box-sizing: border-box;
    width: 100%;
    padding: $leading $content-margin $leading $content-margin;

    .lead-in {
        font-family: "Sharelife Semi Bold";
    }
}

.graphic_oral_histories,
.live_sketches {
    h2 {
        font-size: 2rem;
        line-height: 1.15;
        margin-top: $component-gap;
    }

    h2 + .story {
        margin-top: -3rem;
    }
}

body:not(.home) .overview {
    padding: $leading $content-margin 0;
    margin: 0 auto;
}

main {
    font-size: $text-size;
    line-height: $leading;
    margin-top: 4.8125rem;

    & > div:last-child {
        padding-bottom: 4rem;
    }
}

.primary-title-block {

    .home & {
        background-image: url("../img/sol_logo_ul.svg");
        background-repeat: no-repeat;
        box-sizing: border-box;
        height: 56.26vw;
        margin-top: $leading;
        max-height: 13.375rem;
        max-width: 22.5rem;
    }

    &:before {
        background-image: url("../img/tp_logo.svg");
        background-size: cover;
        box-sizing: border-box;
        content: "";
        display: block;
        height: 80px;
        left: 4rem;
        position: absolute;
        top: 0.8rem;
        width: 200px;
        z-index: 3;
    }
}

.listing {
    width: 600px;
    margin: 0 3rem;
}

.vimeo {
    padding: 56.25% 0 0 0;
    position: relative;
}

.media-submitted {
    .stories-intro-body {
        margin-top: $leading;
    }
}

.feature,
.intro,
.project,
.period,
.historic {
    $image-size: 13rem;
    $ring-size: calc($image-size * 1.1);
    $image-inset: calc(($text-width - $image-size ) / 2);
    $ring-offset-h: calc($image-inset / 2);
    $ring-offset-v: calc($ring-size * 0.41);

    .overview-feature {
        color: $color-buff;
    }

    figure,
    .image-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: start;
    }

    figure img,
    .image-wrapper img,
    .hero-image img {
        width: $image-size;
    }

    figure img,
    .image-wrapper img {
        border-radius: 50%;
        height: $image-size;
    }

    figure {
        position: relative;
        margin: $leading 0 $leading;

        figcaption {
            flex: 100%;
            font-size: 1rem;
            margin: $leading-half 3rem 0 3rem;
            padding: $leading-half 6% 0;
            text-align: center;

            .lead-in {
                display: block;
            }
        }
    }

    .history figure figcaption {
        padding-top: $leading-half;
    }

    figure:after,
    .image-wrapper:after,
    .smart-frame-wrapper:after {
        color: $color-red;
        content: "\e900";
        font-family: "icomoon";
        font-size: $ring-size;
        left: auto;
        position: absolute;
        top: $ring-offset-v;
    }

    .soundcloud:nth-child(2) .image-wrapper:after {
        color: $color-primary;
    }

    .overview-feature figure:nth-child(4):after,
    .content-feature.history figure:nth-child(5):after{
        color: $color-primary;
    }

    .hero-image {
        display: none;
    }
}

.hero-popup-container {
    cursor: pointer;
    display: none;

    .show-hero-popup & {
        display: block;
    }

    &:before {
        background-color: #000;
        bottom: 0;
        content: "";
        left: 0;
        opacity: 0.75;
        position: fixed;
        right: 0;
        top: 0;
        z-index: 9;
    }

    .hero-popup {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        z-index: 10;
    }
}

.feature,
.intro {
    font-family: "Sharelife Semi Bold";
}

.project {
    font-family: "Sharelife Light";
}

.toolbox {
    @extend %text-buff;

    .primary-title-block .toolbox-intro-body {
        h1 {
            padding-bottom: $leading;
        }

        .secondary-image {
            margin: $leading 0;

            img {
                width: 100%;
            }

            .caption {
                margin: $leading-half 0;
            }

            &:nth-child(5) {
                margin-bottom: 0;
            }
        }
    }

    .primary-title-block .main-image,
    .activity .activity-images {
        padding: $leading 0;

        img {
            width: 100%;
            padding-bottom: $leading-half;
        }

        .caption p + p {
            text-indent: 0;
        }
    }

    .activity-images {
        padding-top: 0;

        img {
            margin-top: $leading;
        }

        picture:first-child img {
            margin-top: 0;
        }
    }

    &.bringing-your-story-to-life-through-art .activity:nth-child(2) .activity-images img {
            margin-top: 0;
    }

    .caption {
        font-size: 1rem;
    }

    .detail {
        padding: 0 $content-margin;
        margin: 0 auto;

        h2 {
            padding: $leading-plus-half 0 $leading-half;
        }

        p:last-child {
            text-indent: 0;
        }

        .bullets {
            + h2 {
                padding-top: 0;
                margin-bottom: $leading-half;
            }
        }

        .bullets .bullet-section,
        .activity {
            h2 {

                &:first-child {
                    padding-top: 0;
                }

                & + ul h3 {
                    margin-top: 0;
                }

                & + p {
                    padding: $leading-half 0 0;
                }
            }
            margin-bottom: $leading-double;

            &.plain {
                margin-bottom: $leading;

                &:nth-child(3) h3 {
                    margin-top: calc($leading * -1);
                }

                & + .bullet-section h2 {
                    margin-top: $leading-double;
                }
            }

            ul {
                list-style-type: disc;
                margin-left: 1.2rem;

                li {
                    margin-top: $leading-half;

                    ul {
                        list-style-type: circle;
                    }
                }

                &.full-out {
                    margin-left: 0;
                    list-style-type: none;

                    li {
                        margin-top: 0;
                        margin-bottom: $leading-half;
                    }
                }
            }
            &.plain {
                img {
                    margin: $leading 0;
                }

                &:nth-child(4) img {
                    width:90%;
                }

                &:nth-child(5) {
                    img {
                        width:40%;
                        margin-bottom: $leading-half;

                        + figcaption {
                            margin-bottom: $leading-half;
                        }
                    }
                    figure + figure figcaption {
                        margin-bottom: $leading;
                    }
                }
            }

            p.full-out-spaced {
                text-indent: 0;
                margin: $leading 0;

                + p {
                    text-indent: 0;
                }
            }

            blockquote {
                margin: $leading-double 0;
            }
        }

        .conclusion {
            margin-top: $leading;
            margin-bottom: 2rem;

            & h2 {
                padding-top: 0;
                padding-bottom: $leading;
            }

            p {
                text-indent: 0;
                margin-bottom: $leading;
            }
        }
    }

    main {
        margin-bottom: $component-gap;
    }
}

.toolbox.capturing-stories .bullets > .bullet-section:nth-child(2) > ul br {
    display: inline;
}

.toolbox.bringing-your-story-to-life-through-art {
    .activity:first-child .activity-images picture:nth-child(4) img {
        max-width: 19.5rem;
    }
    .activity:nth-child(2) {
        margin-bottom: 0;
    }
}

.bttn {
    background-color: $color-midnight;
    border-radius: 0.3rem;
    border: none;
    color: $color-white;
    cursor: pointer;
    font-size: 1rem;
    margin-top: 1rem;
    padding: 1rem;

    &:hover {
        background-color: $color-primary;
    }
}

.pdfs {
    $pdf-icon-aspect-ratio: 1.4;
    display: flex;
    margin-top: $leading;

    .pdf {
        flex: 1;

        .download-link {
            font-size: 1rem;
            font-weight: 700;
            line-height: 1.1rem;
            height: 100%;
            width: 100%;
            text-decoration: none;

            .download-details {
                color: $color-midnight;
                padding-right: 3rem;

                .download-details__description {
                    display: block;
                    font-size: 0.8rem;
                    line-height: 1rem;
                }
            }
        }

        .download-details {
            $icon-width: 3.5rem;
            $icon-height: calc($icon-width * $pdf-icon-aspect-ratio);
            background-image: url("../img/pdf_icon_w.svg");
            background-repeat: no-repeat;
            background-size: $icon-width $icon-height;
            padding-top: calc($icon-height + 0.5rem);
        }
    }
}

.cookies,
.privacy-policy,
.terms-of-service {
    background-color: $color-white;

    h1, h2, h3, h4 {
        color: $color-midnight;
        font-family: "Sharelife Semi Bold";
        padding: 0;
        text-transform: none;
    }

    h1 {
        clip-path: none;
        height: auto;
        margin: 0;
        overflow: initial;
        position: static !important;
        width: auto;
        word-wrap: normal !important;
        font-size: 2rem;
        line-height: 2.4rem;
        margin: 1rem 0 0.5rem;
    }

    h2 {
        font-size: 1.4rem;
        line-height: 1.68rem;
        margin: 1.5rem 0 0.5rem;
    }

    h3,
    h4 {
        font-size: 1.2rem;
        line-height: 1.44rem;
    }

    h1 + h3,
    h1 + h4 {
        margin-bottom: 0.5rem;
    }

    p + h3,
    p + h4 {
        margin-top: 1rem;
    }

    p {
        color: $color-midnight;
    }

    main {
        margin-top: 0;
        padding: 10vw;
        box-sizing: border-box;
    }

    .nav {
        display: none;
    }

    @media (min-width: 700px) {
        main {
            max-width: 35rem;
            padding: 4.375rem 0;
            margin: 0 auto;
        }
    }
}

@media (min-width: 450px) {

    .feature,
    .intro,
    .project {
        figure figcaption {
            padding: 0.65625rem 12% 0;
        }
    }
}

@include media-width(min-width, small) {
    $text-margin: responsive-property(small, text-margin);
    $text-width: responsive-property(small, text-width);

    main {
        width: 100vw;

        .graphic_oral_histories,
        .live_sketches {
            padding: $leading $content-margin 0;
        }

        h1,
        .media-submitted & .stories-intro-body,
        .toolbox-intro-body,
        .toolbox & .primary-title-block .main-image {
            padding: 1.2rem 12% 0;
        }

        .graphic_oral_histories h2,
        .live_sketches h2 {
            padding: 0 12% 0;

            + .story {
                margin-top: -2rem;
            }
        }

        .toolbox & .detail {
            padding: 1.2rem calc(12% + 15px) 0;
        }

        .toolbox-intro-body h1 {
            padding: 1.2rem 0 0;
        }

        .component {
            padding: 01.5rem $text-margin;

            /* No padding on nested component */
            .component {
                padding: 0;
            }
        }
    }

    .overview {
        padding-left: $text-margin;
    }
}

// Custom breakpoint
@media (min-width: 700px) {

    main {
        $padding-side: calc((100vw - 490px) / 2);

        h1,
        .media-submitted & .stories-intro-body,
        .toolbox-intro-body,
        .toolbox & .primary-title-block .main-image,
        .toolbox & .detail {
            width: 490px;
            margin: auto;
        }

        .component {
            padding: 1.5rem $padding-side;
        }

        .overview {
            padding: $leading $padding-side;
        }

        .graphic_oral_histories,
        .live_sketches {
            padding: 0;

            h2 {
                padding: 0 $padding-side;
            }
        }
    }
}

@include media-width(min-width, medium) {

    .feature,
    .intro,
    .project {
        figure figcaption {
            padding: 0.65625rem 24% 0;
        }
    }

    .toolbox.bringing-your-story-to-life-through-art .activity:first-child .activity-images {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .caption:nth-child(6) {
            padding-top: 2.5rem;
            width: calc(100% - 21rem);
        }
    }

    .toolbox.bringing-your-story-to-life-through-art .activity:nth-child(2) .activity-images {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        picture {
            width: 50%;

            &:first-child {
                width: 100%;
            }
        }
    }
}

@include media-width(min-width, mega-large) {
    $content-margin: responsive-property(mega-large, content-margin);
    $content-width: responsive-property(mega-large, content-width);

    body:not(.home) .overview {
        padding: $leading $content-margin 0;
        width: 100%;

        .primary-title-block {
            width: 100%;

            h1 {
                padding: 1.2rem 0 0;
                text-align: left;
                width: 100%;
            }
        }
    }

    .toolbox main .detail {
        padding: $leading $content-margin 0;
        width: $content-width;
    }

    .capturing-stories .bullets > .bullet-section:nth-child(2) > ul > li:last-child:after,
    .capturing-stories .bullets > .bullet-section:nth-child(3) > ul > li:after,
    .capturing-stories .bullets > .bullet-section:nth-child(4) > ul > li:after {
        color: $color-red;
        content: "\e900";
        font-family: "icomoon";
        left: auto;
        position: absolute;
    }

    .capturing-stories .bullets {
        @include wave-block-bottom(1130px);
        margin-bottom: calc($leading * 2.5);
        position: relative;
    }

    body.media-submitted .overview .primary-title-block .stories-intro-body,
    body.toolbox .overview .primary-title-block .toolbox-intro-body {
        padding: 1.2rem 0 0;
        text-align: left;
        width: 50%;
        margin-left: 0;
    }

    main .primary-title-block{
        margin-top: 0;

        .capturing-stories &,
        .bringing-your-story-to-life-through-art &,
        .bringing-your-stories-to-life-through-words-and-music &,
        .bringing-your-stories-to-life-through-film & {
            margin-bottom: 3rem;
        }

        &:before {
            left: $content-margin;
        }

        .toolbox & {
            display: flex;
            column-gap: 4rem;

            .toolbox-intro-body,
            .main-image {
                margin-top: 0;

                img {
                    width: 100%;
                }
            }

            .toolbox-intro-body {
                flex: 1;
            }

            .main-image {
                flex: 2;
                padding: 0;
            }
        }

        .toolbox.safari & {
            .toolbox-intro-body {
                margin-right: 4rem;
            }
        }
    }

    main {
        margin-top: 8.8125rem;

        .component,
        .graphic_oral_histories h2,
        .live_sketches h2 {
            padding: 2rem $content-margin;
        }

        .overview {
            padding: 0.5rem $content-margin 0;
        }

        .intro .hero-image {
            display: block;
        }

        .toolbox & {
            .overview .primary-title-block .toolbox-intro-body h1 {
                padding-bottom: $leading;
            }

            .bullets {
                display: flex;
                flex-wrap: wrap;
                column-gap: 4rem;

                .bullet-section > ul {
                    display: flex;
                    column-gap: $leading-double;

                    li {
                        flex: 1;
                    }
                }
            }
        }

        .bringing-your-story-to-life-through-art & {
            .activity:nth-child(2) .activity-images img {
                margin-top: 0;
            }

            .conclusion {
                h2 {
                    column-span: all;
                }

                p {
                    text-indent: 0;
                }
            }
        }

        .detail .conclusion {
            column-count: 3;
            column-gap: 4.5rem;
            margin-bottom: 4rem;
        }



        .intro .pdfs .pdf .download-link .download-details {
            background-position-y: 3.8rem;
            line-height: 1.3;
            padding-top: 3.5rem;
        }
    }

    .toolbox.capturing-stories .bullets > .bullet-section:nth-child(2) {
        color: $color-midnight;
        position: relative;
        padding: calc($leading-double * 1.5) $leading-double $leading-double;
        margin-top: -$leading-double;

        > ul {
            flex-wrap: wrap;
            justify-content: flex-end;
            margin-top: $leading;
            padding-bottom: 1rem;

            > li {
                flex: 1;
            }
        }


        h2 {
            color: $color-midnight;
        }

        ::before {
            content: "";
            background-color: $color-buff;
            clip-path: polygon(0 4%, 100% 2%, 98% 93%, 0% 97%);
            position: absolute;
            bottom: 0;
            left: 0;
            top: 0;
            right: 0;
            z-index: -1;
        }

    }

    .toolbox.capturing-stories .bullets {
        > .bullet-section:first-child {
            @include wave-block(1130px);
            padding: $leading-double 0 $leading-plus-half;
            margin-bottom: 5rem;
            position: relative;
        }

        > .bullet-section:nth-child(2) > ul,
        > .bullet-section:nth-child(3) > ul,
        > .bullet-section:nth-child(4) > ul {
                margin-top: 0;
                padding-bottom: 5rem;
                position: relative;

                span {
                    display: block;
                }
        }

        > .bullet-section:nth-child(3),
        > .bullet-section:nth-child(4) {
            margin-bottom: 0;
            width: 100%;

            > ul {
                flex-wrap: nowrap;
                padding: $leading 0 $leading-double;
                width: 100%;

                > li {
                    position: relative;
                    left: auto;

                    &:nth-child(3) p {
                        width: 90%;
                    }
                }
            }
        }

        > .bullet-section:nth-child(4) {
            flex: 1;

            h2 {
                width: 20ch;
                flex: 1;
            }
        }

        > .bullet-section:nth-child(2) > ul> li:last-child,
        > .bullet-section:nth-child(3) > ul> li,
        > .bullet-section:nth-child(4) > ul> li {
            background-color: $color-buff;
            border-radius: 50%;
            bottom: 1rem;
            box-sizing: border-box;
            color: $color-midnight;
            height: 10rem;
            list-style-type: none;
            padding: 1rem;
            text-align: center;
            text-decoration: none;
            text-decoration: none;
            text-transform: uppercase;
            width: 10rem;

            p {
                margin-top: 0.5rem;
                font-family: "Prater Sans";
                font-size: 1.4rem;
                line-height: 1.6rem;
            }

            &:after {
                font-size: 11rem;
                left: -0.5rem;
                top: 4.2rem;
            }
        }

        > .bullet-section:nth-child(3) > ul> li:nth-child(3) p {
            width: 90%;

        }

        > .bullet-section:nth-child(2) > ul> li:last-child {
            left: calc(50% - 5rem);
            position: absolute;
        }

        > .bullet-section:nth-child(3) > ul> li,
        > .bullet-section:nth-child(4) > ul> li {
            p {
                padding: 0 2rem;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                z-index: 1;
            }

            &:nth-child(3) p {
                padding: 0;
            }

            &:after {
                font-size: 17.1rem;
                left: -0.5rem;
                top: 7.4rem;
            }

        }

        .bullet-section:nth-child(3) > ul> li,
        .bullet-section:nth-child(4) > ul> li {
            height: 16rem;
            width: 13.68rem;
        }
    }

    .toolbox.capturing-stories .detail .conclusion h2 {
        margin-bottom: 2.5rem;
    }
    .toolbox .activity {
        display: flex;
        flex-wrap: wrap;

        > h2 {
            flex: 10
        }

        > p {
            flex: 17;
            margin-top: -0.6rem;
            text-wrap: balance;
        }
    }
    .toolbox.bringing-your-story-to-life-through-art  {
        .activity:first-child {
            @include wave-block(1130px);
            margin-bottom: 3rem;
            padding: $leading-double 0 calc($leading * 2.5);
            position: relative;

            > p {
                margin-top: 0.7rem;
            }

            .activity-images {
                picture:first-child {
                    width: 50%;
                }

                .caption {
                    &:nth-child(3) {
                        order: 1;
                    }
                    &:nth-child(6) {
                        padding-top: 0;
                        width: 13rem;
                    }
                }

                picture:nth-child(4) img {
                    margin-top: -1.2rem;
                }
            }

            .activity-detail {
                display: flex;

                h2 {
                    flex: 10;
                }

                > ul {
                    flex: 17;
                    margin-top: -0.6rem;
                }

            }
        }
        .activity:nth-child(2) {
            display: flex;
            flex-wrap: wrap;

        @include wave-block-bottom(1130px);
        margin-bottom: calc($leading * 2.5);
        position: relative;

            .activity-images picture {
                width: 33%;
            }
        }
    }

    body.toolbox.bringing-your-story-to-life-through-art .activity:nth-child(2) .activity-images picture:first-child {
        width: 33%;
    }

    .toolbox.bringing-your-stories-to-life-through-words-and-music {
        .primary-title-block {
            .toolbox-intro-body {
                flex: 10;
            }
            .main-image {
                flex: 21;
            }
        }
        .bullets {
            .bullet-section {
                display: flex;
                flex-wrap: wrap;

                h2 {
                    flex: 10;
                }

                &.plain + .bullet-section h2 {
                    margin-top: 0;
                }

                .bullet-section-text {
                    column-count: 2;
                    flex: 18;
                }

                &:first-child {
                    @include wave-block(1130px);
                    margin-bottom: 3rem;
                    padding: $leading-double 0 calc($leading * 2.5);
                    position: relative;

                    blockquote {
                        display: none;
                    }
                }

                &:nth-child(7) {
                    @include wave-block(1130px);
                    margin: 3rem 0;
                    padding: $leading-double 0 calc($leading * 2.5);
                    position: relative;
                }

                &:nth-child(8) {
                    @include wave-block-bottom(1130px);
                    padding: 0 0 calc($leading * 2.5);
                    position: relative;
                }

                &:nth-child(7),
                &:nth-child(8) {
                    .bullet-section-text > ul li:first-child {
                        margin-top: 0;
                    }
                }
            }

            .bullet-section.plain {
                display: block;
                padding-left: 25.5rem;

                h3 {
                    margin-top: 0;
                }

                .bullet-section-text {
                    column-count: 1;

                    > p:nth-child(2) img {
                        width: 300px;
                    }
                }

                &:nth-child(5) {
                    .bullet-section-text {
                        position: relative;

                        figure {
                            display: flex;

                            img {
                                width: 130px;
                            }

                            figcaption {
                                position: absolute;
                                top: 9.6rem;
                            }

                            + figure {
                                left: 12rem;
                                position: absolute;
                                top: 2.68rem;

                                figcaption {
                                    top: 7rem;
                                }

                                + p {
                                    margin-top: 3.5rem;
                                }
                            }
                        }
                    }
                }
            }
        }

        .conclusion p {
            break-inside: avoid;
        }
    }

    .toolbox.bringing-your-stories-to-life-through-film {
        .main-image .caption {
            text-align: right;
            width: 100%;
        }
        .toolbox-intro-body {
            position: relative;

            img {
                margin: $leading 0 $leading-half;
            }

            .secondary-image {
                .caption {
                    margin-top: 0;
                }

                & + .secondary-image {
                    position: absolute;
                    width: 22.3rem;
                    left: 25.7rem;
                    top: 20.3rem;

                    img {
                        border: 0.5rem solid $color-midnight;
                    }
                    .caption {
                        margin-top: -0.5rem;
                        margin-left: 0.5rem;
                    }

                }

            }
        }
        .activity:first-child,
        .activity:nth-child(3) {
            @include wave-block(1130px);
            margin-bottom: 3rem;
            padding: $leading-double 0 calc($leading * 2.5);
            position: relative;
        }

        .activity:nth-child(2) {
            padding-bottom: $leading;
        }

        .activity .activity-detail {
            margin-left: 26.35rem;
        }
    }

    .safari {
        &.toolbox.capturing-stories .bullets {
            .bullet-section li {
                margin-right: $leading-double;
            }

            > .bullet-section:nth-child(3) {
                margin-right: 2.625rem;
            }

            .bullet-section.plain:nth-child(5) .bullet-section-text figure img {
                height: 80px;
            }

            .bullet-section.plain:nth-child(5) .bullet-section-text figure + figure figcaption {
                width: 150px;
            }

            .bullet-section:nth-child(2) > ul> li:last-child,
            > .bullet-section > ul> li {
                background-color: transparent;
            }

            > .bullet-section:nth-child(3) > ul> li p,
            > .bullet-section:nth-child(4) > ul> li p {
                margin-left: 0.5rem;
                color: $color-white;
            }
        }

        &.toolbox.bringing-your-story-to-life-through-art {
            .conclusion p {
                break-inside: avoid;
            }
        }

        &.toolbox.bringing-your-stories-to-life-through-words-and-music {
            .bullets {
                .bullet-section .bullet-section-text > p:first-child {
                    margin-top: 0.45rem;
                }

            }

            .bullet-section.plain:nth-child(5) .bullet-section-text figure {
                img {
                    height: 4rem;
                }
                figcaption {
                    width: 10rem;
                }
            }
        }
    }
}

@import "../components/nav/nav",
"../components/footer/footer",
"../components/period/period",
"../components/story/story";
