.component--period {
    $content-width: responsive-property(xsmall, content-width);
    @extend %text-buff;

    position: relative;

    blockquote {
        padding-bottom: $leading-half;

        + p {
            padding-bottom: $leading;
        }
    }

    figure {
        figcaption {
            margin: $leading-half 0 0;
            text-align: left;
            padding: $leading-half 0 0;

            small {
                display: block;
            }
        }
    }

    &--early-history {
        padding-bottom: $leading-plus-half;

        figure:nth-child(3) {
            @include circular-image(16rem, $leading-double, $color-primary);
        }

        figure:nth-child(5) {
            @include circular-image(12rem, $leading, $color-red);
        }

        figure:nth-child(10) {
            @extend %unbordered-figure;
            display: flex;
            column-gap: 1rem;

            img {
                width: 7.375rem;
            }

            figcaption {
                flex: 5rem;

                .safari & {
                    margin-left: 1rem;
                }
            }
        }
    }

    &--medieval-thurrock {
        padding-top: $leading-plus-half;

        .content-period {
            @include wave-block($content-width);

            figure {
                @extend %unbordered-figure;

                img {
                    width: 18rem;
                }
            }

            figcaption p + p {
                text-indent: 0;
            }
        }
    }

    &--modern-era {        
        .content-period figure {
            @include circular-image(16rem, $leading-double, $color-red);
        }

        .smart-frame-wrapper {
            $image-size: 16rem;
            @include smart-frame-wrapper($image-size, 2rem, $color-primary);

            .smart-frame-content {
                margin: -0.1rem 0 0 -2.4rem;
                width: 122%;

                smart-frame-embed {
                    height: calc(var(--hratio) * 1.22 * $image-size);
                }
            }

            & + p {
                margin-top: $leading;
                font-size: 1rem;
                width: 8rem;
            }
        }        
    }

    &--twentieth-century {        
        padding-top: 0;
        
        .content-period {
            @extend %text-red-reversed;
            background: transparent;
            box-sizing: border-box;
            color: $color-white;
            display: flex;
            flex-wrap: wrap;
            overflow: visible;
            padding: $leading $leading-double $leading $leading;
            position: relative;

            &:before {
                // Background on pseudo before to avoid clipping children
                content:"";
                position:absolute;
                z-index:-1;
                top:0;
                left:0;
                right:0;
                bottom:0;
                clip-path: polygon(0 4%, 100% 2%, 95% 98%, 0% 100%);
                background: $color-red;
            }

            main & figure {
                @extend %unbordered-figure;
                order: 1;
                margin-top: 0;
    
                img {
                    transform: rotate(2.95deg);
                    width: 100%;
                }
            }

            h4 {
                margin-top: -0.2rem;
                order: 2;
                padding-bottom: $leading-half;
            }

            p {
                order: 2;

                &:nth-last-child(2) {
                    padding: $leading 0;
                    text-indent: 0;
                }
            }
        }
    }

    &--wwii-present-day {
        padding-top: 0;
        
        figure:nth-child(9) {
            @include circular-image(16rem, $leading, $color-primary);
            width: 16rem;
        }
    }

    @include media-width(min-width, small) {
        $text-width: responsive-property(small, text-width);

        main &--twentieth-century {
            padding: 0 15vw;
        }

        main &--wwii-present-day {
            padding-bottom: 0;
        }

        &--medieval-thurrock  .content-period {
            @include wave-block($text-width);
        }
    }

    @media (min-width: 700px) {
        $content-width: 490px;

        div:first-child {
            width: 490px;
            margin: auto;
        }

        &--early-history {
            .content-period {
                display: flex;
                flex-wrap: wrap;
                column-gap: 1rem;

                h4,
                figure,
                p {
                    order: 2;
                }

                h4:first-child,
                h4:first-child + p {
                    order: 1;
                }
            }

            figure:nth-child(3) {
                order: 1;
                width: 16rem;
            }
            
            figure:nth-child(5) {
                align-content: start;
                margin-left: 1rem;
                order: 1;
                width: 12rem;
            }
        }

        &--medieval-thurrock {
            .content-period {
                @include wave-block($content-width);

                blockquote + p + p {
                    width: 55%;
                }
    
                figure {
                    align-content: start;
    
                    img {
                        order: 2;
                        margin-top: -6rem;
                    }
    
                    figcaption {
                        order: 1;
                        flex: 30%;
                    }
                }
            }
        }

        &--modern-era {        
            figure:nth-child(3) {
                @include circular-image(18.5rem, $leading, $color-red);
                column-gap: 1rem;
                margin: $leading-double 0;

                &:after {
                    left: -0.75rem;
                }

                figcaption {
                    flex: 3rem;
                }
            }

            .smart-frame-wrapper {
                $image-size: 30rem;
                @include smart-frame-wrapper($image-size, 4rem, $color-primary);
                
                .smart-frame-content smart-frame-embed {
                    height: calc(var(--hratio) * 1.22 * $image-size);
                }

                &:after {
                    left: -0.5rem;
                    top: 14rem;
                }
                
                & + p {
                    width: 8rem;
                }
            }
        }

        main &--twentieth-century {
            margin: auto;
            padding: 0;
            width: 490px;
        }

        &--wwii-present-day {
            figure:nth-child(9) {
                @include circular-image(16rem, $leading-double, $color-primary);
                column-gap: 1rem;
                padding-bottom: $leading;
                width: 100%;

                &:after {
                    left: -0.75rem;
                }

                figcaption {
                    flex: 3rem;
                }
            }
        }
    }

    @include media-width(min-width, mega-large) {
        $content-margin: responsive-property(mega-large, content-margin);
        $content-width: responsive-property(mega-large, content-width);

        div:first-child {
            width: 100%;
            margin: auto;
        }

        &--early-history {
            .content-period {
                columns: 2;
                column-gap: 33.33%;
                display: block;
                padding-bottom: $leading-double;

                figure {
                    position: absolute;

                    &:nth-child(3) {
                        @include circular-image(20rem, $leading-double, $color-primary);
                        top: -1.5rem;
                        left: calc($content-margin + 27rem);

                        &:after {
                            top: 9.3rem;
                        }
                    }

                    &:nth-child(5) {
                        @include circular-image(14rem, -15.2rem, $color-red);
                        &:after {
                            left: auto;
                            right: -0.5rem;
                            top: 2.342rem;
                        }
                        right: $content-margin;
                        width: 23.5rem;

                        figcaption {
                            order: 1;
                            flex: 3rem;

                            .safari & {
                                margin-top: -0.8rem;
                            }
                        }

                        img {
                            order: 2;
                            margin-top: -4rem;
                        }
                    }

                    &:last-child {
                        top: 8rem;

                        .safari & {
                            left: calc($content-margin + 47.2rem);

                        }

                        figcaption {
                            margin-top: 5rem;
                        }
                    }
                }

                p:nth-child(8) {
                    padding-top: 16.4rem;
                }
            }
        }

        &--medieval-thurrock {
            .content-period {
                @include wave-block($content-width);
                padding: $leading 0 $leading-double;

                blockquote,
                p {
                    margin-left: calc( 33% + 0.9rem );
                }

                blockquote + p {
                    padding-bottom: $leading-half;

                    + p {
                        padding-top: 0;
                        width: auto;
                    }
                }

                figure {
                    justify-content: end;
                    position: absolute;
                    top: 8rem;
                    width: 23rem;

                    .safari & img{
                        margin-left: 4rem;    
                    }
                    
                    figcaption {
                        bottom: auto;
                        left: 0;
                        position: absolute;
                        top: -6rem;
                        width: 8rem;

                        p {
                            margin: 0;

                            & + p {
                                margin-top: 5.1rem;
                            }
                        }
                    }
                }
            }

        }

        &--modern-era { 
            .content-period {
                column-gap: 2rem;
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
                height: 48rem;
                padding-top: $leading-double;

                .safari & {
                    height: 52rem;
                }

                h4,
                p {
                    order: 2;
                    width: 22rem;
                }

                h4:first-child,
                p:nth-child(2),
                p:nth-child(5),
                p:nth-child(6),
                figure,
                blockquote,
                .smart-frame-wrapper {
                    width: 22rem;
                    order: 1;

                    + p {
                        order: 1;
                    }
                }

                h4:first-child {
                    margin-top: 0;
                    width: 19rem;
                }

                figure:nth-child(3) {
                    @include circular-image(21rem, $leading-double, $color-red);
                    &:after {
                        left: -0.3rem;
                        top: 9.8rem;
                    }

                    figcaption p {
                        width: 10rem;
                    }

                }

                .smart-frame-wrapper {
                    $image-size: 24rem;
                    @include smart-frame-wrapper($image-size, 4rem, $color-primary);
                    bottom: $leading-double;
                    left: calc( $content-margin + 21.8rem);
                    position: absolute;

                    .safari & {
                        left: calc( $content-margin + 20.5rem);
                    }
                
                    .smart-frame-content smart-frame-embed {
                        height: calc(var(--hratio) * 1.22 * $image-size);
                    }

                    + p {
                        width: 7.5rem;
                        left: calc( $content-margin + 17rem);
                        position: absolute;
                        bottom: $leading-double;
                    }
                }

                p:nth-child(6) {
                    margin-top: -$leading-half;
                    padding: 0 0 28rem;

                    .safari & {
                        padding: 0 0 32rem;
                    }
                }

                h4:nth-child(7) {
                    margin-top: 0;
                }
            }
        }

        main &--twentieth-century {
            width: $content-width;

            .content-period {
                align-content: start;
                height: 37rem;
                padding-left: 69%;
                padding-right: 1.5rem;

                .safari & {
                    padding-left: 67%;
                }

                &:before {
                    clip-path: polygon(0 4%, 100% 2%, 98% 93%, 0% 97%);
                }

                figure {
                    position: absolute;
                    left: -3rem;
                    top: 2rem;
                    width: 70%;

                    .safari & {
                        width: 68%;
                        top: 2.5rem;
                    }
                }

                h4 {
                    padding-top: $leading-double;

                    + p {
                        padding-right: 0.75rem;
                    }
                } 

                p:nth-last-child(2) {
                    padding-right: 2rem;
                }  
            }
        }

        &--wwii-present-day {
            .content-period {
                columns: 3;
                column-gap: 2rem;

                h4 {
                    break-inside: avoid;

                    &:nth-child(1) {
                        margin-top: 0;

                        .safari & {
                            margin-top: 0.3rem;
                        }
                    }
                }
                
                figure {
                    left: $content-margin;
                    position: absolute;
                    top: 19rem;
                    width: 16rem;

                    figcaption {
                        position: absolute;
                        top: -1rem;
                        left: 15rem;
                        width: 8rem;
                    }
                }

                p.full-out {
                    break-inside: avoid;
                }

                p:nth-child(2) {
                    padding-bottom: 20rem;

                    .safari & {
                        padding-bottom: 21rem;
                    }
                }
            }
        }
    }
}
