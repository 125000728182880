.project {
    $content-width: responsive-property(xsmall, content-width);

    @keyframes overlay-fade-out {
        from {opacity: 1;}
        to {opacity: 0;}
    }

    p {
        margin: $leading-half 0;
    }

    &__intro {

        h2 {
            padding: 1.5rem 0 0;
        }

    }

    .project_image {
        margin: $component-gap 0;
        width: 100%;
    }

    @include media-width(min-width, small) {
        $text-width: responsive-property(small, text-width);
    }

    // Custom breakpoint
    @media (min-width: 700px) and (max-width: 1299px) {

        &:not(.component--project--song) .media-wrapper--soundcloud {
            height: 50rem;
            display: flex;
            flex-wrap: wrap;
            column-gap: 4rem;

            .component--soundcloud {
                flex-direction: column;
                flex-wrap: wrap;
                width: calc(50% - 2rem);
                column-gap: 4rem;
                margin: 0;

                .soundcloud-text {
                    margin-left: 0;
                    margin: 1.3125rem 0 0;
                }

                &:first-child {
                    height: 18.375rem;
                    width: 100%;
                    margin: $leading 0 0;
                }


                &:first-child .soundcloud-text,
                &:first-child .soundcloud-wrapper {
                    width: calc(50% - 2rem);
                }
            }
        }
    }

    @include media-width(min-width, mega-large) {
        display: flex;
        gap: 2rem;

        &__intro {
            flex: auto;
            width: 21.875rem;

            h2,
            p {
                padding-right: 2rem;
            }
        }

        .media-wrapper {
            display: flex;
            flex: auto;
            flex-wrap: wrap;
            column-gap: 2rem;
            position: relative;
            width: 46.875rem;

            .safari & {
                gap: 0;
            }

            .component {
                &--soundcloud {
                    flex-direction: column;
                    flex: 1;

                    .safari & {
                        padding: 0 1rem;
                    }

                    .soundcloud-text {
                        margin: $leading 0 0;
                    }
                }
            }
        }

        &.component--project {
            $content-width: responsive-property(mega-large, content-width);
            $yt-width: 275.625px;
            $yt_height: 490px;

            &--film {
                flex-wrap: wrap;

                .project__intro {
                    flex: 50rem;
                    padding-right: calc(100% - 50rem);
                }

                .media-wrapper {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    width: $content-width;
                    height: 45rem;
                    gap: 0.5rem;

                    .component--youtube {
                        flex: 1;
                        width: 18.887rem;
                        height: 10.624rem;

                        .safari & {
                            margin-right: 0.5rem;
                        }

                        .youtube-wrapper {
                            height: 10.624rem;

                            .event-overlay {
                                height: 10.6rem;
                                width: 17.3rem;
                            }
                        }
                    }

                    .component--youtube.active {
                        background-color: $color-midnight;
                        flex: auto;
                        height: 26.297rem;
                        margin-right: 22rem;
                        order: -1;
                        width: 46.751rem;

                        .safari & {
                            background-color: transparent;
                        }

                        .youtube-wrapper {
                            height: 26.297rem;

                            .event-overlay {
                                animation-duration: 1s;
                                animation-name: overlay-fade-out;
                                height: 26.297rem;
                                width: 46.751rem;
                            }
                        }
                    }
                }
            }
        }
    }
}
