.component--story {

    .media-submitted & {
        position: relative;
        z-index: 1;
    }

    .content-story {
        align-items: start;

        .story-title-panel {
            overflow: hidden;
            border-radius: 0.01px;
            margin-bottom: -3rem;
            padding: 0 0 1rem 0.2rem;
        }

        .story-head {
            background-color: $color-red;
            box-sizing: border-box;
            clip-path: polygon(0 0, 100% 5%, 95% 88%, 5% 100%);
            color: $color-white;
            max-width: 100%;
            padding: 1.25rem 0.5rem 1.25rem 1.25rem;
            transform: rotate(-1.5deg);
            width: max-content;

            &:first-child {
                margin-top: 1.5rem;
            }

            h3,
            h4,
            p {
                width: max-content;
                margin-right: 1.25rem;
                max-width: 90%;
                text-wrap: balance;

                &:last-child {
                    margin-bottom: 1.3125rem;
                }
            }

            h3 {
                margin-top: 0;
            }
        }

        &--youtube {
            .story-title-panel {
                margin-bottom: -2rem;
            }

            .media-wrapper {
                margin-top: -1rem;

                .event-overlay {
                    height: 100%;
                    width: 100%;
                }
            }
        }

        &--soundcloud {
            .media-wrapper {
                margin-top: -1.15rem;

                .safari & {
                    margin-top: 0;
                }
            }

            .story-media {
                margin-bottom: 0;
            }

            .component--soundcloud {
                margin-top: -3.7rem;
                padding: 0;

                iframe {
                    right: 0;
                    width: 100%;
                }
            }
        }

        &--image {
            .story-media {
                margin: -1.5rem 0 -1rem;

                img {
                    margin: 0 auto;
                    width: 100%;
                }

                .media-submitted & {
                    overflow: hidden;
                }
            }
        }

        &--text {
            .story-body {
                margin-top: 3rem;
            }
        }

        .story-media+p {
            padding-top: $leading-half;
        }

        &--image .story-media:after,
        .story-media .image-wrapper:after {
            color: $color-primary;
        }

        h2+& .story-body {
            margin-top: -3rem;
        }

        .safari & .story-body p:first-child,
        .safari & .story-text p:first-child {
            margin-top: 0.3rem;
        }
    }

    &--text-only-user-story .content-story .story-head {
        padding-bottom: 0.25rem;

        .safari & {
            margin-bottom: $leading;
        }
    }

    &:nth-child(odd) {
        .story-head {
            background-color: $color-primary;
            color: $color-midnight;
        }

        .content-story--image .story-media:after,
        .story-media .image-wrapper:after {
            color: $color-red;
        }
    }

    @media (min-width: 400px) {
        .component--soundcloud {
            margin-top: $leading-half;
        }
    }

    @include media-width(min-width, small) {
        padding-bottom: $leading;

        main .story-container &:not(:last-child) {
            padding-bottom: 2.5rem;
        }

        .content-story .story-head {
            padding: 1.75rem 1rem 1.25rem 1.75rem;
        }

        &--text-only-user-story .content-story .story-head {
            padding-bottom: 0.5rem;
        }
    }

    @include media-width(min-width, smallish) {
        .media-submitted & .content-story.content-story--image .story-media img {
            margin-top: -1rem;
        }
    }

    @include media-width(min-width, mega-large) {
        padding-bottom: $leading;

        main .story-container &:not(:last-child) {
            padding-bottom: 5rem;
        }

        .content-story {
            display: flex;

            .story-title-panel {
                flex: 1.04;
            }

            .story-head {
                clip-path: polygon(0 0, 90% 3%, 88% 88%, 0% 100%);
                column-gap: 2rem;
                padding-left: 1.5rem;
                width: 100%;

                &:first-child {
                    margin-top: 0.75rem;
                }

                h3,
                h4,
                p {
                    margin-right: 0;
                    width: 80%;
                }
            }

            &--youtube {
                .story-title-panel {
                    margin-top: -1.5rem;
                }

                .media-wrapper>div {

                    .youtube-wrapper {
                        aspect-ratio: 1.77;
                        height: auto;

                        .safari & {
                            height: 26.3rem;
                        }
                    }
                }
            }

            &--soundcloud {
                .component--soundcloud {
                    margin-top: 0.5rem;
                }

                .soundcloud-wrapper {
                    width: 50%;

                    .media-submitted & {
                        width: 100%;
                    }
                }
                &.content-story--has-text .soundcloud-wrapper {
                    width: 100%;
                }
            }

            &--image {
                .story-media {
                    margin: 0;

                    &:after {
                        font-size: 23rem;
                        top: 9.6rem;
                    }

                    .safari & {
                        margin-top: 1rem;

                        &:after {
                            top: 9.4rem;
                        }
                    }

                    +p {
                        break-inside: avoid;
                        padding-top: 0;
                    }

                    img {
                        width: 95%;
                        height: auto;
                    }
                }
            }

            .media-submitted &.content-story--image .story-media img {
                margin-top: -3rem;
            }

            &--text {
                .story-body {
                    margin-top: 0;
                }
            }

            .story-body {
                flex: 2;

                .safari & .story-media.component--soundcloud {
                    .image-wrapper {
                        margin-top: 0.5rem;

                        &:after {
                            top: 6.3rem;
                        }
                    }

                }
            }

            &--has-text:not(.content-story--youtube) .story-body,
            &.content-story--youtube .story-body .story-text {
                columns: 2;
                column-gap: 2rem;

                .media-wrapper--youtube>div {
                    column-span: all;
                }
            }

            h2+& .story-body {
                margin-top: -5rem;
            }
        }

        &:nth-child(odd) .content-story .story-head {
            transform: rotate(1deg);
        }
    }
}