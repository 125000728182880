$color-primary: #20b3ad;
$color-primary-bright: #3af1ea;
$color-primary-light: #a5dedc;
$color-primary-dark: #1c6569;
$color-red: #e14e18;
$color-red-light: #ffd8c9;
$color-red-dark: #972900;
$color-red-muted: #b57158;
$color-midnight: #151c24;
$color-buff: #e1c49a;
$color-buff-bright: #faa754;
$color-buff-dark: #b78f55;
$color-white: #fff;


$text-size: 1.09375rem;
$leading: 1.3125rem;
$leading-half: calc( $leading / 2 );
$leading-plus-half: calc( $leading * 1.5 );
$leading-double: calc( $leading * 2 );

$logo-size: 51px;
$nav-height: 77px;
$component-gap: 30px;
$component-gap-small: 15px;
$nav-tp-logo-offset: 1.4rem;

$transition-durations: 0.3s;
