.component--stories {
    padding-top: 1rem;

    .content-stories {

        &:before {
            @include wave-pseudo-element(100%);
            position: relative;
            top: 0;
        }

        .story-container {
            padding-top: 1rem;
        }

        .stories-intro {
            h2 {
                padding: 1rem 0;
            }

            &-body p {
                @extend %text-buff;
                font-family: "Sharelife Semi Bold";

                &:not(:first-child) {
                    text-indent: 0;
                    margin-top: $leading;
                }
            }
        }


        .story-head {

            h4 {
                margin-top: $leading-half;

                &+p {
                    margin-bottom: $leading;
                }
            }
        }

        .content-story {
            .media-wrapper {
                padding-bottom: $leading;
            }

            &--soundcloud .media-wrapper:after {
                height: $leading-half;
            }

            &--youtube .media-wrapper:after {
                column-span: all;
            }
        }
    }

    @media (min-width: 400px) {
        .content-stories .content-story--soundcloud .media-wrapper:after {
            height: $leading;
        }
    }

    @include media-width(min-width, small) {
        .content-stories .stories-intro {
            padding-bottom: 2.5rem;
        }
    }

    @include media-width(min-width, mega-large) {

        .content-stories {
            padding-top: 2rem;

            .stories-intro {

                padding: $leading-double 0 5rem;
                display: flex;
                column-gap: 1rem;

                h2 {
                    flex: 1;
                    padding: 0;

                    .safari & {
                        flex: 1.05;
                    }
                }

                &-body {
                    flex: 2;
                }
            }

            &:after {
                height: $leading-double;
            }

            .content-story {
                .story-body {

                    .media-wrapper+p,
                    p:first-child {
                        margin-top: 0;
                    }

                    .media-wrapper {
                        padding-bottom: $leading-plus-half;
                    }
                }

                &--soundcloud .media-wrapper {
                    margin-top: 0.2rem;
                }
            }
        }
    }
}