.nav {
    $bar-height: 3px;
    $bar-transition: 0.5s;
    $bar-width: 26px;
    $nav-height: 77px;
    $content-margin: responsive-property(xsmall, content-margin);
    $toggle-padding: 30px;
    $toggle-size: 17px;

    background: transparent;
    font-family: "Prater Sans";
    font-size: 1.7rem;
    line-height: 1.875rem;
    height: $nav-height;
    position: absolute;
    text-transform: uppercase;
    top: $nav-tp-logo-offset;
    z-index: 2;

    &:before {
        background-color: #000;
        content: "";
        height: 100vh;
        opacity: 0;
        pointer-events: none;
        position: absolute;
        right: 0;
        bottom: 1rem;
        transition: opacity $bar-transition;
        width: 100vw;
        z-index: -1;
    }

    .nav-active & {
        height: 100vh;

        &:before {
            left: 0;
            opacity: 0.6;
            pointer-events: all;
        }
    }

    a {
        position: relative;
        text-decoration: none;

        &:visited {
            color: $color-midnight;
        }

        &:hover {
            color: $color-red;
        }
    }

    .capturing-stories & .nav__submenu-entries-list-link:nth-child(2) a:before {
        content: "Capturing Stories ";
    }

    &__elements {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: 0 $content-margin;

        &__nav__element {
            display: block;
        }
    }

    &__home {
        order: 1;

        &-link {
            background-image: url("../img/sol_logo.svg");
            background-size: cover;
            border: 1px solid #fff;
            box-sizing: border-box;
            display: block;
            height: $logo-size;
            width: $logo-size;
            margin-top: calc(($nav-height - $logo-size) / 2);
        }
    }

    &__submenu {
        order: 0;

        &-toggle {
            cursor: pointer;
            height: $toggle-size;
            padding: 30px 10px 14px 0;
            position: relative;
            top: 0;
            width: $toggle-size;
            z-index: 1;

            &-bar {
                background-color: rgba(255, 255, 255, 1);
                border-radius: 0;
                border: none;
                height: $bar-height;
                padding: 0;
                position: absolute;
                transition: $bar-transition;
                width: $bar-width;

                &:before {
                    top: -8px;

                    .nav-active & {
                        transform: rotate(45deg);
                    }
                }

                &:after {
                    top: 8px;

                    .nav-active & {
                        transform: rotate(135deg);
                    }
                }

                &:before,
                &:after {
                    content: "";
                    background-color: $color-white;
                    height: $bar-height;
                    left: 0;
                    position: absolute;
                    transition: $bar-transition;
                    width: $bar-width;

                    .nav-active & {
                        top: 0;
                    }
                }

                .nav-active & {
                    background-color: rgba(255, 255, 255, 0);
                }

            }

            &:hover {
                .nav__submenu-toggle-bar {
                    body:not(.nav-active) &,
                    &::before,
                    &::after {
                        background-color: $color-primary-bright;
                    }
                }
            }

        }

        &-entries {
            $submenu-entries-width: 90vw;
            background-color: $color-primary;
            clip-path: polygon(0 0, 100% 0, 93% 100%, 0% 100%);
            height: 100vh;
            left: calc($submenu-entries-width * -1);
            position: absolute;
            right: 0;
            top: calc($nav-tp-logo-offset * -1);
            transition: all 0.5s ease;
            width: $submenu-entries-width;

            .resizing & {
                transition: none;
            }

            .nav-active & {
                left: 0;
            }

            &-list-link {
                margin-bottom: 1.875rem;
            }

            &-list {
                margin: 8.5rem 0 0 20px;
                max-width: 15rem;
            }
        }
    }

    &__tel-num {
        font-weight: 500;
    }

    // Custom breakpoint
    @media (min-width: 385px) {

        &__submenu-entries {
            $submenu-entries-width: 22rem;
            left: calc($submenu-entries-width * -1);
            width: $submenu-entries-width;
        }

        &__submenu-entries-list {
            margin-left: 5rem;
        }
    }

    @include media-width(min-width, mega-large) {
        $content-margin: responsive-property(mega-large, content-margin);
        $content-width: responsive-property(mega-large, content-width);
        background-color: $color-primary;
        clip-path: polygon(0 0, 100% 0, 100% 88%, 0% 100%);

        a:after {
            content: none;
        }

        .nav-active &:before {
            opacity: 0;
            pointer-events: all;
        }

        &__elements {
            margin: 0 $content-margin;
            position: relative;
            width: responsive-property(mega-large, content-width);
        }

        &__submenu {

            &-toggle {
                display: none;
            }

            &-entries {
                background-color: transparent;
                clip-path: none;
                height: $nav-height;
                left: 0;
                top: 0;
                width: $content-width;
                transition: none;

                &-list {
                    display: flex;
                    font-size: 1.5rem;
                    justify-content: flex-end;
                    margin: 0;
                    max-width: none;
                    padding-top: 13px;

                    &-link {
                        margin: 0.5rem 2.2rem 0.7rem 0.7rem;
                        min-width: 3.5rem;
                        text-align: center;

                        .nav__title-pre {
                            color: $color-primary-dark;
                            display: block;
                            font-size: 1rem;
                            left: 0;
                            min-width: 2rem;
                            position: absolute;
                            text-align: left;
                            top: -1.34rem;
                            width: 100%;
                            width: 100%;
                        }

                        &:first-child {
                            margin-left: 10rem;

                            .nav__title-pre {
                                left: -0.55rem;
                            }
                        }

                        &:nth-child(2) .nav__title-pre {
                            left: 0.4rem;
                        }

                        &:nth-child(3) .nav__title-pre {
                            left: 0.8rem;
                        }

                        &:nth-child(4) .nav__title-pre {
                            left: 0.9rem;
                        }

                        &:nth-child(5) .nav__title-pre {
                            left: 2.5rem;
                        }

                        &:nth-child(6) .nav__title-pre {
                            left: 4.9rem;
                        }

                        &:hover .nav__title-pre {
                            color: $color-red;
                        }
                    }

                    .home &-link {
                        margin-top: 1rem;
                    }
                }
            }
        }
    }

    @include media-width(max-width, mega-large) {
        .nav__submenu-entries-list-link {
            position: relative;

            &:after {
                background-image: url("../img/wave.svg");
                background-size: cover;
                box-sizing: border-box;
                content: "";
                display: block;
                height: 0.5rem;
                position: absolute;
                bottom:-1.1rem;
                width: 7.5rem;
                z-index: 3;
            }
        }
    }
}

.nav__home {
    display: none;
}