body {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

main {
    /* Allow main to grow with content */
    flex: 1;
}

footer {
    $text-margin: responsive-property(xsmall, text-margin);
    background-color: $color-primary;
    box-sizing: border-box;
    margin-top: 2rem;
    position: relative;

    &:before {
        background-image: url("../img/wave1.svg");
        background-repeat: repeat-x;
        background-size: 12.5rem 3.75rem;
        content: "";
        display: block;
        height: 3.75rem;
        position: absolute;
        top: -2rem;
        width: 100%;
    }

    .footer-content {
        box-sizing: border-box;
        display: grid;
        font-family: "Sharelife Semi Bold";
        font-size: 1.1rem;
        grid-template-columns: "1fr";
        line-height: 2.2rem;
        padding: 4rem $text-margin 5rem;
        position: relative;
        text-align: center;

        .footer-logo {
            height: auto;
            margin: 0 auto;
            width: 17.5rem;
        }
    
        .link-text-hide,
        .partners .partner-name {
            @extend %screen-reader-text;
        }
    
        address {
            margin-top: 0.5rem;
    
            a {
                color: $color-midnight;
            }
        }

        .acknowledgements {
            margin: 2rem auto 0;
            max-width: 25rem;

            h4 {
                margin-bottom: 0.5rem;
            }

            .partners {
                align-items: center;
                justify-content: center;
                display: flex;
                flex-wrap: wrap;

                .partner {
                    margin: 0 1rem;

                    &--glc {
                        width: 5.5rem;
                    }

                    &--gei {
                        width: 6.5rem;
                    }

                    &--toc {
                        width: 5.5rem;
                    }

                    &--tms {
                        width: 4.5rem;
                    }

                    &--gg {
                        width: 4.25rem;
                    }


                    a {
                        width: 100%;

                        img {
                            width: 100%;
                        }
                    }
                }
            }
        }
    
        .socials {
            margin-top: 0.7rem;
    
            .social-link {
                color: $color-midnight;
                text-decoration: none;
    
                &:before {
                    font-family: "icomoon";
                    font-size: 1.5rem;
                }
    
                &--twitter:before {
                    content: "\e906";
                }
    
                &--instagram:before {
                    content: "\e905";
                }
    
                &--facebook:before {
                    content: "\e904";
                }
            }
    
            li {
                display: inline-block;
                margin-right: 0.7rem;
            }
        }
    
        .hf-logo {
            height: auto;
            margin: 2.5rem auto 0;
            width: 15rem;
        }
    
        .legal {
            margin-top: 1.9rem;
    
            li {
                display: inline-block;
                margin: 0 0.6rem;
    
                a {
                    color: $color-midnight;
                }
    
                &:nth-child(3) {
                    margin-right: 0;
                }
            }
        }
    }

    @media (min-width: 416px) {
        .acknowledgements .partners .partner.partner--glc {
            margin-left: 1.2rem;
        }
    }

    @include media-width(min-width, "medium") {
        .footer-content {
            grid-template-columns: 1fr min-content;
            grid-template-rows: min-content 8rem min-content;
            grid-template-areas:
            "logotp logohf"
            "links1 acknowledgements"
            "links2 acknowledgements";
            column-gap: 2rem;
            margin: 0 auto;
            row-gap: 1.3rem;
            text-align: left;
            padding: 4rem 0 5rem 0;
            width: 40rem;
    
            .footer-logo {
                align-self: end;
                grid-area: logotp;
                margin: 0;
            }
    
            .hf-logo {
                align-self: end;
                grid-area: logohf;
                margin: 0 0 0.85rem -0.5rem;
            }
    
            address {
                grid-area: links1;
                margin-top: 0;
            }

            .acknowledgements {
                grid-area: acknowledgements;
                margin-top: 0;
                max-width: none;

                h4 {
                    margin-top: 0;
                }

                .partners {
                    justify-content: start;

                    .partner,
                    .partner.partner--glc {
                        margin: 0 1rem 0 0;
                    }
                }
            }
    
            .legal {
                grid-area: links2;
                margin-top: 0;
                width: 17.5rem;

                li {
                    margin: 0 1.2rem 0 0;

                    &:nth-child(3) {
                        margin-right: 0;
                    }
                }
            }
        }
    }
    
    @include media-width(min-width, "extra-large") {
        .footer-content {
            grid-template-columns: 1.72fr 1.6fr min-content;
            grid-template-rows: max-content min-content;
            grid-template-areas:
            "logotp links1 logohf"
            "links2 acknowledgements acknowledgements";
            width: 60rem;

            address {
                margin-top: 2rem;
            }

            .acknowledgements {
                margin: 0;

                h4 {
                    margin: 0;
                }

                .partners {
                    justify-content: space-between;
                    margin-top: -1.2rem;
                }
            }

            .footer-logo {
                margin-bottom: 2rem;
            }

            .hf-logo {
                margin: 0 0 3rem;
            }
        }
    }

    // Custom breakpoint
    @media (min-width: 1440px) {
        .footer-content {
            column-gap: 1rem;
            grid-template-columns: repeat(min-content, 4);
            grid-template-rows: max-content min-content;
            grid-template-areas:
            "logotp links1 links2 logohf"
            ". acknowledgements acknowledgements .";
            width: 72.625rem;

            .legal {
                grid-area: links2;
                margin-top: 2rem;
                width: 17.5rem;
            }

            address {
                grid-area: links1;
            }
        }

    }
}