@import "../ui/switch_slider/switch_slider",
"../ui/bttn_close/bttn_close";

.modal-wrapper {
    background-color: rgba(0, 0, 0, 0.5);
    height: 100vh;
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: fixed;
    top: 0;
    visibility: hidden;
    width: 100vw;
    z-index: 99;
    transition: opacity 0.5s;
}

.modal {
    background-color: $color-white;
    border-radius: 5px;
    color: $color-midnight;
    box-sizing: border-box;
    height: 100%;
    line-height: 1.2rem;
    overflow: scroll;
    padding: 3rem;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 100;

    h2, h3, h4 {
        color: $color-midnight;
        font-family: "Sharelife Semi Bold";
        padding: 0;
        text-transform: none;
    }

    h2 {
        font-size: 1.7rem;
        line-height: 2rem;
        margin-bottom: 1rem;
    }

    h3 {
        font-size: 1.3rem;
        line-height: 1.5rem;
        margin-top: 2rem;
    }

    h4 {
        font-size: 1.1rem;
        margin: 1.5rem 0 0.5rem;
    }

    a {
        &:link {
            color: $color-midnight;
        }

        &:visited {
            color: $color-primary-dark;
        }

        &:hover {
            color: $color-primary;
        }

        &:active {
            color: $color-primary-dark;
        }
    }

    .bttn__consent {
        margin-top: 2rem;
    }

    .close_bttn {
        position: absolute;
        top: -0.2rem;
        right: 1rem;

        &:hover {
            .close_bttn-bar:before,
            .close_bttn-bar:after {
                background-color: $color-primary;
            }
        }
    }

    @media (min-width: 450px) {
        height: auto;
        left: 50%;
        max-height: calc(100vh - 2rem);
        max-width: 400px;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    @media (min-width: 900px) {
        max-width: 500px;

        .details {

        column-gap: 0.5rem;
        display: flex;
        flex-wrap: wrap;
        }
    }
}