.component.component--callout {

    color: $color-white;

    h2 {
        font-size: 16vw;
        line-height: 16vw;
        padding-left: 0;
        padding-right: 0;
        position: relative;
        margin: 1rem 0;

        &:before,
        &:after {
            background-image: url("../img/wave.svg");
            background-size: cover;
            box-sizing: border-box;
            content: "";
            display: block;
            height: 6vw;
            position: absolute;
            top: 0.8rem;
            width: 90vw;
            z-index: 3;
        }

        &:after {
            top: auto;
            bottom: 1rem;
        }
    }

    p {
        @extend %text-midnight;
        font-family: "Sharelife Semi Bold";
        margin-bottom: $leading;

        & + p {
            text-indent: 0;
        }
    }

    .puffs {
        .puff {
            background-color: $color-buff;
            box-sizing: border-box;
            display: block;
            margin: $leading 0;
            padding: 1rem;
            text-decoration: none;

            h3 {
                margin: 0 0 $leading-half;
                color: $color-midnight;
            }

            .puff-details {
                img {
                    display: block;
                    margin-bottom: $leading-half;
                    width: 100%;
                }

                .puff-text p {
                    font-family: "Sharelife Light";
                    color: $color-midnight;
                }
            }
        }
    }

    @media (min-width: 400px) {

        #research {
            width: 22.5rem;
        }
        h2 {
            font-size: 4rem;
            line-height: 4rem;

            &:before,
            &:after {
                height: 1.5rem;
                width: 22.5rem;
            }
        }
    }

    @include media-width(min-width, small) {
        padding-bottom: 3rem;
    }

    @include media-width(min-width, smallish) {
        .puffs {
            display: flex;
            flex-wrap: wrap;
            column-gap: $leading;
            row-gap: $leading;

            .puff {
                margin: 0;
                width: calc(50% - $leading-half);
            }
        }
    }

    @include media-width(min-width, mega-large) {
        padding: 4.25rem calc((100vw - 1130px) / 2) 3rem;

        .research {
            align-items: start;
            display: grid;
            column-gap: 2rem;
            row-gap: 2rem;
            grid-template-columns: 21.875rem 1fr;
            grid-template-areas:
                "title text"
                "puffs puffs";


            h2 {
                grid-area: title;
                margin-top: -0.8rem;

                &:before,
                &:after {
                    width: 21.5rem;
                }
            }

            .callout-text {
                column-count: 2;
                column-gap: 2rem;
            }

            p {
                margin-top: 0;
                break-inside: avoid;
            }

            .puffs {
                grid-area: puffs;

                .puff {
                    width: calc(25% - 1rem);
                }
            }
        }
    }
}